import React from 'react';

import '../styles/css/TextArea.css';

export default class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.teaxtAreaRef = React.createRef();
  }

  componentDidMount() {
    // this.teaxtAreaRef.current.focus();
  }

  componentDidUpdate() {
    // this.teaxtAreaRef.current.focus();
  }

  render() {
    return (
      <div className="TextArea">
        <textarea
          className="p-2 p-sm-3 p-md-4 p-xl-5"
          aria-label="textarea"
          value={this.props.textareaValue}
          onChange={this.props.textareaChange}
          ref={this.teaxtAreaRef}
        ></textarea>
      </div>
    );
  }
}
